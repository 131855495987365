import React from 'react';
import AboutPageHero from '../components/about/AboutPageHero';
import OurStory from '../components/about/OurStory';
import PageMeta from '../components/common/PageMeta';
import CtaTwo from '../components/cta/CtaTwo';
import FeatureImgThree from '../components/features/FeatureImgThree';
import Team from '../components/team/Team';
import TestimonialTwo from '../components/testimonial/TestimonialTwo';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import { memo } from 'react';
const About = () => {
  return (
    <Layout>
      <PageMeta title='About us| Metaverse experts| Metaverse solutions' description="Discover the driving force behind Metawalk - your premier partner in metaverse development. Dive into our about us section to uncover a team of passionate experts dedicated to shaping the future of virtual reality and immersive digital experiences. With a commitment to innovation and a track record of success, we blend creativity with technical prowess. Learn more about how Metawalk is not just building the metaverse; we're defining its future. Join us on this exciting journey as we lead the way in metaverse development, creating immersive worlds that captivate and inspire."
      keywords={"Metaverse Experts,Visionary Development Team,Creative Metaverse Solutions,Passionate XR Enthusiasts,Metaverse Architects,Transformative Virtual Experiences,Metaverse Design Authority,Innovation-driven Developers,XR Technology Visionaries,Metaverse Creators      "} />
      <Navbar  navDark  />
      <AboutPageHero />
      <OurStory />
      {/* <FeatureImgThree /> */}
      {/* <Team /> */}
      {/* <TestimonialTwo /> */}
      {/* <CtaTwo /> */}
      <FooterOne footerGradient />
    </Layout>
  );
};

export default About;
