import React from 'react';
import { Link } from 'react-router-dom';
import TestimonialOne from '../testimonial/TestimonialOne';
import { Carousel } from 'react-responsive-carousel';

const FeatureFour = ({video}) => {
  return (
    <>
      <section className='image-feature pt-30 ptb-40'>
        <div className='container'>
          <div className='row justify-content-between align-items-center'>
          <div className='col-lg-6 col-12 order-lg-0'>
              <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
              {/* <video src={video} autoPlay={true}  width={720} height={557} className=' img-fluid'  loop={true} ></video> */}
              <Carousel infiniteLoop={true} interval={1500} emulateTouch={false} swipeable={false} autoPlay={true} stopOnHover={false} autoFocus={true}  showThumbs={false} showArrows={false} showIndicators={false} showStatus={false} style={{borderRadius:"15px !important"}}>
                <div>
                    <img src="assets/img/nft/01.jpg" />
                    
                </div>
                <div>
                    <img src="assets/img/nft/02.jpg" />
                   
                </div>
                <div>
                    <img src="assets/img/nft/03.jpg" />
                  
                </div>
            </Carousel>

              </div>
            </div>
            <div className='col-lg-5 col-12 order-lg-1'>
              <div className='feature-img-content'>
                <div className='icon-box rounded-custom bg-dark shadow-sm d-inline-block'>
                {/* <i class="fal fa-magic text-white"></i> */}
                </div>
                <h2 >Virtual Exhibition / NFT Gallery
</h2>
<div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                <p>
                NFTs (Non-Fungible Tokens) provide a secure and transparent way to establish
ownership and authenticity of digital artwork. Artists can protect their intellectual
property, and collectors can have confidence in the uniqueness and provenance of the
pieces they acquire.

                </p>
                </div>
                <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                <p>
                Can be easily updated and modified, allowing for a dynamic and evergreen showcase of
artwork. Artists can introduce new pieces, make adjustments, or even host ongoing
exhibitions, keeping the virtual space relevant and engaging over time.
                </p>
                </div>
                <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                <p>
                Hosting a virtual exhibition or NFT gallery can be more cost-effective than organizing a physical event. There are no expenses related to venue rental, shipping, or logistics, making it a more budget-friendly option.
                </p>
                </div>
                {/* <p>
                This innovation can revolutionize factory operations, 
                making production lines more agile,
                adaptable, and cost-effective. 	
                </p> */}
                {/* <div className="button-container-3 w-50 bg-light border-0  text-align-left">
  <span className="mas">Know More<i className='far fa-arrow-right ms-2'></i></span> */}

{/* <button type="button" name="Hover" className='btn btn-primary'>
<Link to='/manufacturing-service' className='read-more-link text-decoration-none text-white border-0 fs-4'>


                
                 
                      Know More<i className='far fa-arrow-right ms-2'></i>
                      </Link>
                    </button>  */}
                    {/* </div> */}

              
              </div>
            </div>
            
          </div>
        </div>
      </section>
      {/* <TestimonialOne darkBg /> */}





      {/* <div className='ps-lg-5 pe-lg-5 ps-md-4 pe-md-4'  >
            
           
                          <div className='row pt-5 pb-5 mx-auto justify-content-center align-items-center' >
                          <div className='col-lg-6 col-md-6'style={{width:"600px"}} >
                          <div className='single-article rounded-custom my-3 ' >
                            <Link to='/blog-single' className='article-img'>
                              <img
                                src='assets/img/example/left.jpg'
                                alt='article'
                                className='img-fluid'
                                style={{height:"300px"}}
                              />
                            </Link>
                            <div className='article-content p-4'>
                              <Link to='/blog-single'>
                                <h2 className='h5 article-title limit-2-line-text'>
                                Enhance your prayer journey with the power of 3D immersion.
                                </h2>
                              </Link>
                              <p className='limit-2-line-text'>
                              By harnessing the power of AR technology, individuals can embark on 
            virtual pilgrimages, engage in interactive ceremonies, and gain a 
            profound understanding of the temple's history and significance.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-6 col-md-6'style={{width:"600px"}} >
                          <div className='single-article rounded-custom my-3'>
                            <Link to='/blog-single' className='article-img'>
                              <img
                                src='assets/img/example/right.jpg'
                                alt='article'
                                className='img-fluid'
                                style={{height:"300px"}}
                              />
                            </Link>
                            <div className='article-content p-4'>
                              <Link to='/blog-single'>
                                <h2 className='h5 article-title limit-2-line-text'>
                                Explore the world of real estate like never before with the immersive 
            magic
                                </h2>
                              </Link>
                              <p className='limit-2-line-text'>
                              We're thrilled to invite you to be a part of this transformation that's not 
            only reshaping global landscape but also improving customer 
            engagement, and increasing revenue generation
                              </p>
                            </div>
                          </div>
                        </div>
                       
            
                          </div>
                      </div> */}
    </>
  );
};

export default FeatureFour;
