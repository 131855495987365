import React from 'react';
import { Link } from 'react-router-dom';

const AboutPageHero = () => {
  return (
    <>
      <section
        className='about-header-section ptb-100 pb-0 position-relative overflow-hidden bg-dark '
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat center right",
            paddingTop:"120px"
        }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='section-heading-wrap d-flex justify-content-between z-5 position-relative'>
                <div className='about-content-left ' style={{paddingBottom:"40px"}}>
                  <div className='about-info mb-5'>
                    <h1 className='fw-bold display-6'>
                    Immerse Yourself In The wonders Of The Metawalk
                    </h1>
                    {/* <p className='lead'>
                    Creating tomorrow's solutions, today!
Your journey into metaverse starts with Metawalk, your simple yet powerful guide.{' '}
                    </p> */}
                    {/* <Link to='/career' className='btn btn-info mt-4 me-3'>
                      Open Positions
                    </Link>
                    <Link to='/about-us' className='btn btn-soft-info mt-4'>
                      Meet Our Team
                    </Link> */}
                  </div>
                  {/* <img
                    src='assets/img/about-img-1.jpg'
                    alt='about'
                    className='img-fluid about-img-first mt-5 rounded-custom shadow'
                  /> */}
                </div>
                {/* <div className='about-content-right'>
                  <img
                    src='assets/img/about-img-2.jpg'
                    alt='about'
                    className='img-fluid mb-5 rounded-custom shadow'
                  />
                  <img
                    src='assets/img/about-img-3.jpg'
                    alt='about'
                    className='rounded-custom about-img-last shadow'
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className='bg-white position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z-2 py-5'></div> */}
      </section>
    </>
  );
};

export default AboutPageHero;
