import React, { Component } from "react";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import HeroOne from "./HeroOne";
import FeatureImgContentFour from "../../components/feature-img-content/FeatureImgContentFour";
import FeatureImg from "../../components/features/FeatureImg";
import PageMeta from "../../components/common/PageMeta";
// import TestimonialOne from '../../components/testimonial/TestimonialOne';
import WorkProcess from "../../components/work-process/WorkProcess";
import Featurefive from "../../components/features/Feature-five";
// import PriceOne from '../../components/prices/PriceOne';
import FaqOne from "../../components/faqs/FaqOne";
import IntegrationOne from "../../components/integration/IntegrationOne";
import CtaOne from "../../components/cta/CtaOne";
import FooterOne from "../../layout/Footer/FooterOne";
import FeatureFour from "../../components/features/Feature-four";
import FeatureThree from "../../components/features/Feature-three";
import FeatureOne from "../../components/features/FeatureOne";
import video from "../../components/features/videos/01.mp4";

class HomeSassOne extends Component {
  render() {
    return (
      <Layout>
        <PageMeta
          title="Metawalk|  Metaverse development company
"
          description="Dive into the exciting world of the metaverse with Metawalk. Discover our user-friendly metaverse development solutions and experience a new dimension of virtual reality. Join us in crafting a limitless digital future where creativity knows no bounds. Explore, connect, and create  – your gateway to the next generation of immersive experiences"
       keywords={"Metaverse Development,Virtual Reality Solutions,Augmented Reality Experiences,Digital Twin Technology,Immersive Environments,Spatial Computing,3D Simulation Development,Next-Gen Virtual Worlds,Web3.0 Development,Mixed Reality Solutions       "}
       />
        <Navbar navDark />
        <HeroOne />
        {/* <FeatureOne /> */}
        <IntegrationOne />

        <FeatureImgContentFour video={video} />
        <FeatureImg video={video} />
        <FeatureThree video={video} />
        <FeatureFour video={video} />
        <Featurefive video={video} />

        <CtaOne />

        <WorkProcess />

        {/* <PriceOne paddingTop='pt-60 pb-120' haspriceTitleWithHeader /> */}
        {/* <FaqOne /> */}
        <FooterOne footerGradient />
      </Layout>
    );
  }
}

export default HomeSassOne;
