import React, { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const OffCanvasMenu = memo(() => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false); // 

  useEffect(() => {
    const stickyheader = document.querySelector(".main-header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <div
        className="offcanvas offcanvas-end overflow-y-hidden"
        tabIndex="-1"
        
        id="offcanvasWithBackdrop"
        onClick={()=>{ 
          let bd = document.getElementsByTagName("body")[0]; // Get the first body element
        bd.setAttribute("style","overflow-y: scroll !important") }}
        
      >
        <div className="offcanvas-header d-flex align-items-center mt-4">
          <Link
            to="/"
            className="d-flex align-items-center mb-md-0 text-decoration-none"
          >
            <img
              src="assets/img/logo-white.png"
              alt="logo"
              className="img-fluid ps-2"
              style={{width:"70%"}}
            />
          </Link>
          <button
            type="button"
            className="close-btn text-danger"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="far fa-close"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <ul className="nav col-12 col-md-auto justify-content-center main-menu">
            {/* <li className="nav-item dropdown">
            <Link to="/" className="nav-link">
                Home
              </Link>
              
            </li> */}
            <li>
                  <Link to="/about-us" className="nav-link">
                    About Us
                  </Link>
                </li >
                <li>
                  <a href="https://room.karbh.com/"  className="nav-link" target='_blank'>
                  Experiences
                  </a>
                </li>
                
                {/* <li className="nav-item d-flex justify-content-between align-items-center"   // Show submenu on hover
       >
  <Link to="/services" className="nav-link "  >
    Services
  </Link>
  <span className='dropdown bg-info p-2 rounded ps-3 pe-3 '  onClick={() => setIsSubMenuVisible(isSubMenuVisible==false?true:false)}><i className="fa-solid fa-caret-down dropdown-toggle text-light" id="navbarDropdown" role="button" ></i></span>
  
</li> */}
<ul className={`dropdown-menu  ${isSubMenuVisible ? 'show' : ''}`}   style={{fontSize:"15px"}}>
    <li>
      <Link to="/manufacturing"  className="dropdown-item" >
      Manufacturing Industry
      </Link>
    </li>
    <li>
      <Link to="/restaurant" className="dropdown-item">
      Restaurant Industry
      </Link>
    </li>
    <li>
      <Link to="/education" className="dropdown-item">
      Education Industry
      </Link>
    </li>
    <li>
      <Link to="/interior" className="dropdown-item">
      Interior Industry
      </Link>
    </li>
    {/* Add more submenu items as needed */}
  </ul>
            {/* <li>
                  <Link to="/contact-us" className="nav-link">
                    Contact Us
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/faqs" className="nav-link">
                    FAQs
                  </Link>
                </li> */}

          
          </ul>
          {/* <div className="button-container-3 bg-light border-0  text-align-left m-0 " style={{width:"40%"}}>
  <span className="mas"> Let's connect</span> */}

{/* <button type="button" className='btn btn-primary' name="Hover"> */}

  <Link to='/contact-us' className='button-87 w-75'>
                
                 
Let's connect
</Link>
                    {/* </button>  */}
                    {/* </div> */}
        </div>
      </div>
    </>
  );
})  ;

export default OffCanvasMenu;
