import React from "react";
import { Link } from "react-router-dom";
import {
  Instagram,
  Facebook,
  Linkedin,
  Youtube,
} from "../../components/common/Constanturl";

const FooterOne = ({ footerLight, style, footerGradient }) => {
  const date = new Date();
  const Year = date.getFullYear();

  const Handlemedia = (link) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top ${
            footerLight ? "footer-light" : "bg-gradient"
          } ${footerGradient ? "bg-gradient" : ""}  text-white ptb-50`}
          style={style}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-6 col-lg-4 mb-md-4 ju mb-lg-0 ys" >
                <div className="footer-single-col">
                  <div className="footer-single-col mb-4 d-flex justify-content-lg-start justify-content-center justify-content-sm-start justify-content-md-start">
                    <img
                      src="assets/img/logo-color.png"
                      alt="logo"
                      className="img-fluid logo-white"
                      style={{ width: "220px" }}
                    />
                    {/* <img
                      src='assets/img/logo-color.png'
                      alt='logo'
                      className='img-fluid logo-color'
                      style={{width:"220px"}}
                    /> */}
                  </div>
                  <p style={{maxWidth:"375px"}} className=" text-center text-lg-start text-sm-start" >
                    Immerse your brand in the metaverse revolution with
                    Meatwalk's customized experiences. Explore a world of
                    diverse opportunities that captivate your audience.
                  </p>

                  {/* <form className='newsletter-form position-relative d-block d-lg-flex d-md-flex'>
                    <input
                      type='text'
                      className='input-newsletter form-control me-2'
                      placeholder='Enter your email'
                      name='email'
                      required=''
                      autoComplete='off'
                    />
                    <input
                      type='submit'
                      value='Subscribe'
                      data-wait='Please wait...'
                      className='btn btn-info mt-3 mt-lg-0 mt-md-0'
                    />
                  </form> */}
                  {/* <div className='ratting-wrap mt-4'>
                    <h6 className='mb-0'>10/10 Overall rating</h6>
                    <ul className='list-unstyled rating-list list-inline mb-0'>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 mt-4 mt-md-0  mt-lg-0 text-center">
                {/* <div className="row align-items-center justify-content-between"> */}
                  {/* <div className="col-md-6 align-items-lg-start col-12 justify-content-lg-center align-items-center justify-content-center col-lg-6 mt-4 mt-md-0 mt-lg-0"> */}
                    <div className="footer-single-col " >
                      <h3>Company</h3>
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        {/* <li>
                          <Link to='/' className='text-decoration-none'>
                            Home
                          </Link>
                        </li> */}
                        <li>
                          <Link to="/about-us" className="text-decoration-none">
                            About Us
                          </Link>
                        </li>
                        <li>
                          <a
                            href="https://room.karbh.com/"
                            className="text-decoration-none"
                            target="_blank"
                          >
                            Experience
                          </a>
                        </li>
                        <li>
                          <Link
                            to="/contact-us"
                            className="text-decoration-none"
                          >
                            Contact Us
                          </Link>
                        </li>
                        {/* <li>
                          <Link
                            to='/faqs'
                            className='text-decoration-none'
                          >
                           F
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  
                  {/* <div className='col-md-4 col-6 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Services</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link to='/manufacturing' className='text-decoration-none'>
                          Manufacturing
                          </Link>
                        </li>
                        <li>
                          <Link to='/restaurant' className='text-decoration-none'>
                          Restaurant
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/education'
                            className='text-decoration-none'
                          >
                           Education
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/interior'
                            className='text-decoration-none'
                          >
                            Interior
                          </Link>
                        </li>
                       
                      
                        
                      </ul>
                    </div>
                  </div> */}
                  {/* </div> */}
                 </div>
                 <div className="col-md-12 col-12 col-lg-4 mt-4 mt-md-0 mt-lg-0 text-center">
                    <div className="footer-single-col   " >
                      <h3>Follow Us</h3>
                      <ul className="list-unstyled footer-nav-list  mb-lg-0">
                        <ul className="list-unstyled list-inline footer-social-list mb-0">
                          {/* <li className="list-inline-item">
                            <Link
                              onClick={() => Handlemedia(Facebook)}
                              target="_blank"
                            >
                              <i className="fab fa-facebook-f"></i>
                            </Link>
                          </li> */}
                          {/* <li className="list-inline-item">
                            <Link onClick={() => Handlemedia(Instagram)}>
                              <i className="fab fa-instagram"></i>
                            </Link>
                          </li> */}

                          <li className="list-inline-item">
                            <Link onClick={() => Handlemedia(Linkedin)}>
                              <i className="fab fa-linkedin"></i>
                            </Link>
                          </li>
                          
                          {/* <li className="list-inline-item">
                            <Link onClick={() => Handlemedia(Youtube)}>
                              <i className="fab fa-youtube"></i>
                            </Link>
                          </li> */}
                        </ul>
                        <a
                  href='mailto:join@metawalk.in'
                  className='button-87 mt-auto'
                  style={{width:"60%",marginLeft:"20%"}}
                  name="Hover"
                >
                  Email Us
                </a>
                      </ul>
                    </div>
                  </div>
             
            </div>
          </div>
        </div>

        <div
          className={`footer-bottom ${
            footerLight ? "footer-light" : "bg-dark"
          } ${footerGradient ? "bg-gradient" : ""} text-white py-4`}
        >
          <div className="container">
            <div className="row justify-content-end align-items-center">
              <div className="col-md-12 col-lg-7">
                <div className="copyright-text">
                  <p className="mb-lg-0 mb-md-0">
                    &copy; {Year} METAWALK Rights Reserved.{" "}
                    {/* <Link
                      to='/https://themetags.com/'
                      className='text-decoration-none ms-2'
                    >
                      ThemeTags
                    </Link> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
