import React from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';

const FeatureThree = ({video}) => {
    return (
        <>
          <section className='why-choose-us pt-30 ptb-40'>
            <div className='container'>
              <div className='row justify-content-lg-between align-items-center'>
              <div className='col-lg-6 col-12 order-lg-1'>
                  <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                {/* <video src={video} autoPlay={true} width={720} height={557} className='img-fluid' loop={true}></video> */}
                <Carousel infiniteLoop={true} interval={1500} emulateTouch={false} swipeable={false} autoPlay={true} stopOnHover={false} autoFocus={true}  showThumbs={false} showArrows={false} showIndicators={false} showStatus={false} style={{borderRadius:"15px !important"}}>
                <div>
                    <img src="assets/img/virtual-wedding/01.jpg" />
                    
                </div>
                <div>
                    <img src="assets/img/virtual-wedding/02.jpg" />
                   
                </div>
                <div>
                    <img src="assets/img/virtual-wedding/03.jpg" />
                  
                </div>
                <div>
                    <img src="assets/img/virtual-wedding/04.jpg" />
                  
                </div>
                <div>
                    <img src="assets/img/virtual-wedding/05.jpg" />
                  
                </div>
            </Carousel>

                    {/* <img
                      src='assets/img/screen/widget-11.png'
                      className='img-fluid'
                      alt='feature-img'
                    /> */}
                  </div>
                </div>
                <div className='col-lg-5 col-12 order-lg-0'>
                  <div className='why-choose-content'>
                    <div className='icon-box rounded-custom bg-info shadow-sm d-inline-block'>
                      {/* <i className='fas fa-object-ungroup text-white'></i> */}
                    </div>
                    <h2>Virtual Wedding</h2>
                    <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                    <p>
                    Hosting a virtual wedding can significantly reduce costs associated with traditional
weddings, such as venue expenses, catering, and travel. Couples can allocate their
budget more efficiently or choose to invest in other aspects of their future together.

                    </p>
                    </div>
                    <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                    <p>
                    Virtual weddings often result in smaller, more intimate gatherings physically present at
the venue. This allows couples to focus on the closest family and friends, creating a
more personal and meaningful experience.

                    </p>
                    </div>
                    <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                    <p>
                    Often include features for guest interaction, such as live chat and virtual toasts. This
enhances the overall engagement of the virtual attendees, making them feel more
connected to the celebration.

                    </p>
                    </div>
                    {/* <div className="button-container-3 w-50 bg-light border-0  text-align-left">
  <span className="mas">Know More<i className='far fa-arrow-right ms-2'></i></span> */}

{/* <button type="button"  className=' btn btn-primary'>
<Link to='/interior-industry-service' className='read-more-link text-decoration-none text-white border-0 fs-4'>


                
                 
                      Know More<i className='far fa-arrow-right ms-2'></i>
                      </Link>
                    </button>  */}
                    {/* </div> */}

                    
                 
                    
                  </div>
                </div>
               
              </div>
            </div>
          </section>
        </>
      );
    };

export default FeatureThree;
