import React from "react";
import SectionTitle from "../common/SectionTitle";
import { Link } from "react-router-dom";

const OurStory = () => {
  return (
    <>
      <section className="our-story-section pt-40 pb-40">
        <div className="container pt-5 pb-5">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 col-12 order-lg-1">
              <div className="feature-img-holder d-flex  mt-4 mt-lg-0 mt-xl-0">
                <img
                  src="assets/img/service/team.png"
                  className="img-fluid text-center"
                  width={600}
                  alt="feature-img"
                />
              </div>
            </div>
            <div className="col-lg-5 col-12 order-lg-0">
              <div className="feature-img-content">
                <div className="icon-box rounded-custom bg-dark shadow-sm d-inline-block">
                  {/* <i class="fal fa-magic text-white"></i> */}
                </div>
                <h2>Creating tomorrow's solutions, today!</h2>
                <p>
                  Your journey into metaverse starts with Metawalk, your simple
                  yet powerful guide.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className=" container pt-5 pb-5  ">
          <div className=" row rounded justify-content-around align-items-center ">
            <div
              className="col-lg-5  shadow h-100   shadow-lg mb-5 "
              style={{ borderRadius: "15px", minHeight: "510px" ,minWidth:"40%"}}
            >
              <h2 className="m-3">Our Mission</h2>
              <p className="m-3">
                At Metawalk, our mission is to lead the way in shaping the
                Metaverse as a dynamic, inclusive, and innovative digital space.
                We are committed to fostering a sense of connection, creativity,
                and exploration within this virtual landscape. Through
                cutting-edge technologies and user-centric experiences, we aim
                to empower individuals and businesses to thrive in the
                Metaverse.
              </p>
              <p className="m-3">
                Our dedication to integrity, collaboration, and continuous
                improvement drives us to redefine the boundaries of immersive
                digital reality, creating a space where everyone can contribute
                to and benefit from the limitless possibilities of the
                Metaverse. Join us on this journey as we pioneer the next era of
                interconnected and enriching virtual experiences.
              </p>
            </div>
            {/* <div className="col-auto col-md-2 col-lg-auto col-sm-auto "></div> */}
            <div
              className="col-lg-5 shadow  h-100   shadow-lg bg bg-white   mb-5 "
              style={{ borderRadius: "15px", minHeight: "510px ",minWidth:"40%" }}
            >
              <h2 className="m-3">Our Vision</h2>
              <p className="m-3">
                Our vision is to be at the forefront of the Metaverse
                revolution, driving innovation and transforming the way
                individuals and communities interact with digital reality. We
                envision a Metaverse that transcends boundaries, fostering a
                sense of connection, creativity, and limitless exploration.{" "}
              </p>
              <p className="m-3">
                We aspire to be the go-to destination for individuals and
                businesses seeking a dynamic and enriching presence in the
                Metaverse. By pushing the boundaries of what's possible, we
                strive to catalyze a new era of interconnected and meaningful
                virtual experiences that positively impact the lives of those
                who engage with our platform. Join us as we embark on this
                visionary journey to redefine the possibilities of the digital
                frontier.
              </p>
            </div>
          </div>
        </div>

        {/* <div className="container pt-5 pb-5">
          <div className="row justify-content-lg-between align-items-center">
            <div className="col-lg-5 col-12">
              <div className="why-choose-content">
                <div className="icon-box rounded-custom bg-primary shadow-sm d-inline-block">
                  {/* <i class="fas fa-utensils text-white"></i> *
                </div>
                <h2>Our mission</h2>
                <p>At AiRvisit, our mission is to pioneer the future of Augmented Reality technology. 
We are committed to pushing the boundaries of innovation, creating immersive 
experiences, and transforming the way people interact with the world. Our core purpose 
is to Developing augmented reality for a worldwide audience.</p>
                <p>
                Leading the way into a new era of human-tech interaction through Augmented 
Reality. We believe that AR has the potential to reshape industries, improve lives, and 
bridge the gap between the physical and digital worlds.
                </p>

               
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="feature-img-holder mt-4 mt-lg-0 mt-xl-0">
                <img
                  src="assets/img/screen/widget-11.png"
                  className="img-fluid"
                  alt="feature-img"
                />
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="container pt-5 pb-5">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-5 col-12 order-lg-1">
              <div className="feature-img-content">
                <div className="icon-box rounded-custom bg-dark shadow-sm d-inline-block">
                 
                </div>
                <h2>Our Vision:</h2>
                <p>Our vision is to be the leading force in shaping the future of Augmented Reality 
technology. We aspire to create a world where AR seamlessly integrates with our daily 
lives, transforming the way we work, learn, and interact. </p>
                <p>
                We believe that AR has the power to revolutionize industries, enhance productivity, 
and create unforgettable experiences.
Our ultimate goal is to enable individuals to unlock their full potential through the use of 
AR, making them more informed, efficient, and inspired in their personal and 
professional pursuits.
                </p>
               

            
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-0">
              <div className="feature-img-holder mt-4 mt-lg-0 mt-xl-0">
                <img
                  src="assets/img/screen/widget-12.png"
                  className="img-fluid"
                  alt="feature-img"
                />
              </div>
            </div>
          </div>
        </div> */}

        <div className="container pt-5 pb-5">
          <div className="row justify-content-lg-between position-relative mb-4">
            <div className="col-lg-4 col-12">
              <div className="why-choose-content">
                <div className="icon-box rounded-custom bg-primary shadow-sm d-inline-block">
                  {/* <i class="fas fa-utensils text-white"></i> */}
                </div>
                <h2
                  className=" text-uppercase about-text  position-absolute"
                  style={{ fontSize: "300%", width: "80%", top: "0" }}
                >
                  what sets us apart <br></br>in METAVERSE
                </h2>
              </div>
            </div>
            <div className="col-lg-8 col-12">
              <div className="feature-img-holder mt-4 mt-lg-0 mt-xl-0">
                <img
                  src="assets/img/overlay.png"
                  className="img-fluid "
                  alt="feature-img"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="d-flex row">
              <div className="col-12">
                <p >
                  In the realm of the Metaverse, our team gathers some of the
                  most brilliant minds in immersive technology. As pioneers in
                  Metaverse development, design, and implementation, we possess
                  a profound grasp of the latest trends and emerging
                  technologies. Innovation serves as the cornerstone of our
                  endeavors, propelling us to continually redefine the limits of
                  Metaverse experiences. Our commitment extends to crafting
                  revolutionary solutions that tackle real-world challenges
                  head-on.
                {/* </p>
                <p style={{ textAlign: "justify" }}> */}
                  Recognizing the uniqueness of each client, we customize our
                  Metaverse solutions to align with their specific needs and
                  objectives. Your success becomes our triumph! Upholding the
                  highest standards of quality and performance, we rigorously
                  test our Metaverse projects to ensure they not only meet but
                  exceed expectations. Join us on a journey where cutting-edge
                  technology and personalized solutions converge to shape a
                  transformative Metaverse experience tailored just for you.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container pt-5 pb-5">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 col-12 order-lg-0">
              <div className="feature-img-holder mt-4 mt-lg-0 mt-xl-0">
                <img
                  src="assets/img/service/our_service.png"
                  className="img-fluid"
                  width={500}
                  alt="feature-img"
                />
              </div>
            </div>
            <div className="col-lg-5 col-12 order-lg-1">
              <div className="feature-img-content">
                <div className="icon-box rounded-custom bg-dark shadow-sm d-inline-block">
                  {/* <i class="fal fa-magic text-white"></i> */}
                </div>
                <h2>Our Services</h2>
                <p >
                  In the vast expanse of the Metaverse, our experts offer
                  strategic counsel to empower your business with the effective
                  utilization of immersive technology. Beyond enhancing
                  operations, we specialize in creating engaging and informative
                  experiences for your audience.
                </p>
                <p >
                  Whether you're a visionary business ready to revolutionize
                  your approach or an individual eager to dive into the
                  boundless possibilities of the Metaverse, Metawalk stands as
                  your reliable companion on this exhilarating journey. We
                  extend a warm invitation for you to join us in shaping the
                  future of augmented reality, where possibilities are limitless
                  and innovation knows no bounds.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurStory;
