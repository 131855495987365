import React from 'react';
import { Link } from 'react-router-dom';
// import ReactBubblyEffectButton from 'react-bubbly-effect-button';
import { Carousel } from 'react-responsive-carousel';


const FeatureImgContentFour = ({video}) => {
  return (
    <>
      <section className='why-choose-us ptb-120 '>
        <div className='container'>
          <div className='row justify-content-lg-between align-items-center'>
          <div className='col-lg-6  col-12 order-lg-1'>
              <div className='feature-img-holder  mt-5 mt-lg-0 mt-xl-0'>
                <Carousel infiniteLoop={true} className='rounded' interval={2000} emulateTouch={false} swipeable={false}  autoPlay={true} stopOnHover={false} autoFocus={true}  showThumbs={false} showArrows={false} showIndicators={false} showStatus={false} style={{borderRadius:"15px !important"}}>
                <div >
                    <img src="assets/img/virtual-showroom/01.jpg" />
                    
                </div>
                <div>
                    <img src="assets/img/virtual-showroom/02.jpg"/>
                   
                </div>
                <div>
                    <img src="assets/img/virtual-showroom/03.jpg" />
                  
                </div>
                <div>
                    <img src="assets/img/virtual-showroom/04.jpg" />
                  
                </div> 
                 <div>
                    <img src="assets/img/virtual-showroom/05.jpg" />
                  
                </div>
                <div>
                    <img src="assets/img/virtual-showroom/06.jpg" />
                  
                </div>
                <div>
                    <img src="assets/img/virtual-showroom/07.jpg"/>
                  
                </div>
                <div>
                    <img src="assets/img/virtual-showroom/08.jpg"/>
                  
                </div>
            </Carousel>
                {/* <video src={video} autoPlay={true} width={720} height={557} className='img-fluid' loop={true}></video> */}
              </div>
            </div>
            <div className='col-lg-5 col-12 mt-4 mt-lg-0 order-lg-0'>
              <div className='why-choose-content'>
                {/* <div className='icon-box rounded-custom bg-info shadow-sm d-inline-block'></div> */}
                <h2>Virtual Showroom / Office</h2>
                <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                  <p>
                  Costs associated with maintaining physical showrooms and offices. Virtual spaces eliminate the need for physical infrastructure, utilities, and maintenance expenses, providing a more cost-effective solution.

                </p></div>
                <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                <p>It allows businesses to engage with customers in real time. Through live chat, product demonstrations, and interactive elements, customers can receive personalized assistance, leading to a more immersive and satisfying experience. </p>
                </div>
                {/* <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                <p>
                Interactive features, enhancing the overall user experience.

                </p></div> */}
                <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                <p>
                Visitors can engage with products or services through multimedia presentations, 3D
models, and virtual tours, leading to increased engagement and understanding.
</p>
</div>

                {/* <div className="button-container-3 w-50 bg-light border-0  text-align-left">
  <span className="mas">Know More<i className='far fa-arrow-right ms-2'></i></span> */}

{/* <button type="button" name="Hover" className='btn btn-primary'>
<Link to='/restaurant-industry-service' className='read-more-link text-decoration-none text-white border-0 fs-4'>

                
                 
                      Know More<i className='far fa-arrow-right ms-2'></i>
                      </Link>
                    </button>  */}
                    {/* </div> */}
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgContentFour;
