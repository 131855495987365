import React, { useEffect } from 'react';
import PageHeader from '../components/common/PageHeader';
import PageMeta from '../components/common/PageMeta';
import ContactBox from '../components/contact/ContactBox';
import ContactFormTwo from '../components/contact/ContactFormTwo';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
// import axios from "axios"
import { memo } from 'react';

const Contact = memo(() => {


  // const apicall=()=>{
  //  return axios.get("https://mdm.karbh.com/api/produt/get")
  //   .then((val)=>console.log(val.data))
  // }

  // useEffect(()=>{
  //   try{
  //     apicall()
  //   }
  //   catch(err){
  //   console.error(err.response)
  //   return err.response
  //   }
  // },[])


//   const post=async()=>{
//     try{
//     let data=await axios.post("https://karbh.website/api/help/create",{
//       question:"kalpesh",
// answers:"question",
// order:1,

//     })
//     if(data.data.success===true){
//       console.log(data)
//     }

//   }catch(error){
//     console.error(error)

//   }

//   }

//   useEffect(()=>{
//     post();
//   })


  return (
    <Layout>
            <PageMeta title='Talk to an expert- Metaverse developmetnt| virtual office| virtual showroom| ' description="Connect with the leaders in metaverse development at Metawalk. Reach out through our contact us page to start a conversation about bringing your virtual reality and augmented reality visions to life. Our expert team is ready to collaborate, turning your ideas into immersive digital experiences. Whether you have questions, ideas, or are ready to embark on a metaverse journey, our contact page is your gateway to innovation. Let's build the future together. Contact us today for a seamless blend of technology and creativity in the metaverse." 
            keywords={"Metaverse Development Inquiry,Contact Metaverse Experts,Virtual Reality Consultation,XR Solutions,Get in Touch,Contact Our Metaverse Team,VR/AR Integration Inquiry,Spatial Web Development,Discuss Cross-Platform XR,Virtual Experiences            "}/>

      <Navbar navDark />
      
      <PageHeader
        title="Contact Us"
        desc="Join the 
        conversation! We're just a tap away to answer all your questions"
        
      />
      <ContactBox />
      <ContactFormTwo />
      <FooterOne
        footerGradient
      />
    </Layout>
  );
});

export default Contact;
