import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

const ContactFormTwo = () => {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  // Error state variables
  const [firstnameError, setFirstnameError] = useState('');
  const [lastnameError, setLastnameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [messageError, setMessageError] = useState('');
  const[blanckerror,setBlankerror]=useState('')


  const mailtoLink = `mailto:join@metawalk.in?subject=Contact Form Submission&body=
  First Name: ${firstname}%0D%0A
  ${lastname ? `Last Name: ${lastname}%0D%0A` : ''}
  Email: ${email}%0D%0A
  Phone: ${phone}%0D%0A
  Message: ${message}%0D%0A
`.replace(/\s+/g, '');

const handlemailapi=async()=>{
  try{
    let data=await axios({
      method:"POST",
      url: "https://karbh.com/api/contactSubmit",
      headers: {}, 
      data: {
        "fname":firstname,
        "lname":lastname,
        "email":email,
        "type":"METAWALK",
        "phone":phone,
        "requirement":message,
         // This is the body part
      }
      
    })
      if(data.status==201){
toast.success("Successfully mail send")
      }
    
   else {
      toast.error("Somthing went Wrong! ")

    }
   
    
  }
  catch(error){
    console.log(error)

  }
}

  const handleemail = (e) => {
    e.preventDefault();

    // Reset error messages
    setFirstnameError('');
    setLastnameError('');
    setEmailError('');
    setPhoneError('');
    setMessageError('');
   

    // Simple validation
    if (!firstname || !email || !phone || !message) {
      toast.error('Please fill data in all required fields.');
      return;
    }

    // Additional validation based on your requirements
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phone)) {
      toast.error('Please enter a valid 10-digit phone number.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    // All validation passed
   // Remove spaces
   handlemailapi();

    // Open the default email client
   
  };

  return (
    <>
      <section
        className='contact-us-form pt-60 pb-120'
        style={{
          background:
            "url('assets/img/shape/contact-us-bg.svg')no-repeat center bottom",
        }}
      >
        <div className='container'>
          <div className='row justify-content-lg-between align-items-center'>
            <div className='col-lg-6 col-md-8'>
              <div className='section-heading'>
                <h2>Get in touch with our team</h2>
              </div>
              <form className='register-form'>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label htmlFor='firstName' className='mb-1'>
                      First name <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        id='firstName'
                        required
                        onChange={(e) => setFirstname(e.target.value)}
                        value={firstname}
                        placeholder='First name'
                      />
                    </div>
                    {firstnameError && <div className="text-danger">{firstnameError}</div>}
                  </div>
                  <div className='col-sm-6 '>
                    <label htmlFor='lastName' className='mb-1'>
                      Last name
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        id='lastName'
                        onChange={(e) => setLastname(e.target.value)}
                        value={lastname}
                        name='lastname'
                        placeholder='Last name'
                        aria-label='Last name'
                      />
                    </div>
                    {lastnameError && <div className="text-danger">{lastnameError}</div>}
                  </div>
                  <div className='col-sm-6'>
                    <label htmlFor='phone' className='mb-1'>
                      Phone <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='tel'
                        className='form-control'
                        id='phone'
                        name='phone'
                        required
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        maxLength={10}
                        placeholder='Phone'
                        aria-label='Phone'
                      />
                    </div>
                    {phoneError && <div className="text-danger">{phoneError}</div>}
                  </div>
                  <div className='col-sm-6'>
                    <label htmlFor='email' className='mb-1'>
                      Email<span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='email'
                        className='form-control'
                        id='email'
                        name='email'
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                        placeholder='Email'
                        aria-label='Email'
                      />
                    </div>
                    {emailError && <div className="text-danger">{emailError}</div>}
                  </div>
                  <div className='col-12'>
                    <label htmlFor='yourMessage' className='mb-1'>
                      Message <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <textarea
                        className='form-control'
                        id='yourMessage'
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        required
                        name='message'
                        placeholder='How can we help you?'
                        style={{ height: '120px' }}
                      ></textarea>
                    </div>
                    {messageError && <div className="text-danger">{messageError}</div>}
                  </div>
                </div>
                {blanckerror && <div className="text-danger">{blanckerror}</div>}

                <button type='submit' className='button-87' onClick={handleemail}>
                    Get in Touch
                  </button>
              </form>
            </div>
            <div className='col-lg-5 col-md-12 col-12'>
              <div className='contact-us-img'>
                <img
                  src='assets/img/contact-us-img-2.svg'
                  alt='contact us'
                  // className='img-fluid'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactFormTwo;
