import React from 'react';
import { Link } from 'react-router-dom';
// import video from "assets/img/homepage_video/01.mp4"
import { Carousel } from 'react-responsive-carousel';

const FeatureImg = ({video}) => {

  // const video='assets/img/homepage_video/01.mp4'
  return (
    <>
      <section className='image-feature  ptb-40'>
        <div className='container'>
          <div className='row justify-content-between align-items-center'>
          <div className='col-lg-6 col-12 order-lg-0'>
              <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
              {/* <video src={video} autoPlay={true}  width={720} height={557} className=' img-fluid'  loop={true} ></video> */}
              <Carousel infiniteLoop={true} interval={1500} autoPlay={true} stopOnHover={false} emulateTouch={false} swipeable={false} autoFocus={true}  showThumbs={false} showArrows={false} showIndicators={false} showStatus={false} style={{borderRadius:"15px !important"}}>
                <div>
                    <img src="assets/img/virtual-conf/01.jpg" />
                    
                </div>
                <div>
                    <img src="assets/img/virtual-conf/02.jpg" />
                   
                </div>
                <div>
                    <img src="assets/img/virtual-conf/03.jpg" />
                  
                </div>
                <div>
                    <img src="assets/img/virtual-conf/04.jpg" />
                  
                </div>
            </Carousel>

              </div>
            </div>
            <div className='col-lg-5 col-12 order-lg-1'>
              <div className='feature-img-content'>
                <div className='icon-box rounded-custom bg-dark shadow-sm d-inline-block'>
                {/* <i className="fas fa-briefcase text-white"></i> */}
                </div>
                <h2>Virtual Conference Room</h2>
                <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                <p>
                Equipped with a variety of collaboration tools such as screen sharing, virtual whiteboards, and file sharing that enhance communication and make it easier for participants to collaborate in real-time.</p></div>
{/* <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                <p>
                Enhance communication and make it easier for participants to collaborate in real-time.

                </p>
                </div> */}
                <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                <p>
                Can record easily providing a valuable resource for participants who may need to review
the discussion or for those who were unable to attend. This helps in documentation,
training, and maintaining a record of important decisions.

                </p>
                </div>
                {/* <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                <p>
                Can encourage more active participation, as individuals may feel more comfortable
expressing their thoughts in a virtual setting.
                </p>
                </div> */}
                <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>

                <p>
                Participants can join virtual conferences from the comfort of their own locations, whether
it's their home office, a co-working space, or while on the go. This flexibility
accommodates diverse working preferences and lifestyles.
                </p>
                </div>
                {/* <div className="button-container-3 w-50 bg-light border-0  text-align-left"> */}
  {/* <span className="mas">Know More<i className='far fa-arrow-right ms-2'></i></span> */}

{/* <button type="button" name="Hover" className=' btn  btn-primary'>
<Link to='/education-industry-service' className='read-more-link text-decoration-none text-white border-0 fs-4'>


                
                 
                      Know More<i className='far fa-arrow-right ms-2'></i>
                      </Link>
                    </button>  */}
                    {/* </div> */}

              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImg;
