import React, { useState,memo } from "react";
// import { Link } from "react-router-dom";
import HeroTitle from "../../components/common/HeroTitle";
// import AutoplaySlider from 'react-awesome-slider/hoc/autoplay';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';








const HeroOne = memo(() => {

  // const [isOpen, setOpen] = useState(false);
  // const Slider = (
  //   <AutoplaySlider
  //     play={true}
  //     cancelOnInteraction={false}
  //     interval={6000}
  //     cssModule={AwesomeSliderStyles}
  //   >
  //     <div data-src="assets/img/hero-1.png" />
  //     <div data-src="assets/img/hero-2.png" />
  //     <div data-src="assets/img/hero-3.png" />
  //     <div data-src="assets/img/hero-3.png" />
  //   </AutoplaySlider>
  // );



  return (
    <>
      <section className="hero-section  pb-0   text-white bg-gradient pb-home"> 
        <div className=" container-fluid">
          <div className="row">
           
            <div className="col-lg-12 col-md-12 ps-0 pe-0 pb-0">
              <div className=" ">
                {/* <img
                  src="assets/img/hero-1.png"
                  alt="hero img"
                  className="img-fluid position-relative z-5"
                /> */}
{/* <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
  <ol className="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
  </ol>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img className="d-block w-100" src="https://www.w3.org/TR/2019/NOTE-wai-aria-practices-1.1-20190207/examples/carousel/carousel-1/images/lands-endslide__800x600.jpg" alt="First slide"/>
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="https://images.pexels.com/photos/1774303/pexels-photo-1774303.jpeg?cs=srgb&dl=pexels-lady-escabia-1774303.jpg&fm=jpg" alt="Second slide"/>
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="https://www.w3.org/TR/2019/NOTE-wai-aria-practices-1.1-20190207/examples/carousel/carousel-1/images/lands-endslide__800x600.jpg" alt="Third slide"/>
    </div>
  </div>
  <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div> */}
              {/* <div className="vid"></div> */}
<img className=" img-fluid" style={{ height:"748px",width:"100%",objectFit:"cover"}} src="assets/img/homepage_video/hero-img.jpg"></img>
{/* <video  src="assets/img/homepage_video/hero-img." style={{ he ight:"600px",width:"100% "}} muted   autoPlay="true" loop="true"></video> */}

{/* <Carousel infiniteLoop={true} interval={1500} autoPlay={true} stopOnHover={false} autoFocus={true}  showThumbs={false} showArrows={false} showIndicators={false} showStatus={false}>
                <div>
                    <img src="assets/img/home_crousel/01.png" />
                    
                </div>
                <div>
                    <img src="assets/img/home_crousel/02.png" />
                   
                </div>
                <div>
                    <img src="assets/img/home_crousel/03.png" />
                  
                </div>
            </Carousel> */}

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default HeroOne;
