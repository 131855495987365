import React from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';

const Featurefive = ({video}) => {
    return (
        <>
          <section className='why-choose-us  ptb-40 pb-60'>
            <div className='container'>
              <div className='row justify-content-lg-between align-items-center'>
              <div className='col-lg-6 col-12 order-lg-1'>
                  <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                {/* <video src={video} autoPlay={true} width={720} height={557} className='img-fluid' loop={true}></video> */}
                <Carousel infiniteLoop={true} emulateTouch={false} swipeable={false} interval={1500} autoPlay={true} stopOnHover={false} autoFocus={true}  showThumbs={false} showArrows={false} showIndicators={false} showStatus={false} style={{borderRadius:"15px !important"}}>
                <div>
                    <img src="assets/img/virtual-education/01.jpg" />
                    
                </div>
                <div>
                <img src="assets/img/virtual-education/02.jpg" />

                   
                </div>
                <div>
                <img src="assets/img/virtual-education/03.jpg" />

                  
                </div>
            </Carousel>

                    {/* <img
                      src='assets/img/screen/widget-11.png'
                      className='img-fluid'
                      alt='feature-img'
                    /> */}
                  </div>
                </div>
                <div className='col-lg-5 col-12 order-lg-0'>
                  <div className='why-choose-content'>
                    <div className='icon-box rounded-custom bg-info shadow-sm d-inline-block'>
                      {/* <i className='fas fa-object-ungroup text-white'></i> */}
                    </div>
                    <h2>Virtual Education Center</h2>
                    <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                    <p>
                    Can incorporate a variety of multimedia resources, interactive simulations, and digital
tools to enhance the learning experience. This diversity in content engages students and
caters to different learning styles.
 
                    </p>
                    </div>
                    {/* <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                    <p>
                    Can leverage technology to offer personalized learning experiences. Adaptive learning
systems can tailor the curriculum to individual student needs, providing targeted support
and challenges based on their progress. 
                    </p>
                    </div> */}
                    <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                    <p>
                    Through online discussions, collaborative projects, and virtual classrooms, students can
interact with peers from different cultures and backgrounds, promoting a rich and diverse
learning environment.
                    </p>
                    </div>
                    <div className=' d-flex  gap-2 '>
                <i className="far fa-caret-right text-primary " ></i>
                    <p>
                    Often include features for real-time progress tracking. Students and educators can
monitor performance, completion of assignments, and participation, facilitating timely
feedback and intervention when needed.

                    </p>
                    </div>
                    {/* <div className="button-container-3 w-50 bg-light border-0  text-align-left">
  <span className="mas">Know More<i className='far fa-arrow-right ms-2'></i></span> */}

{/* <button type="button" name="Hover" className=' btn btn-primary'>
  <Link to='/interior-industry-service' className='read-more-link text-decoration-none text-white border-0 fs-4'>


                
                 
                      Know More<i className='far fa-arrow-right ms-2'></i>
                      </Link>
                    </button>  */}
                    {/* </div> */}

                    
                 
                    
                  </div>
                </div>
               
              </div>
            </div>
          </section>
        </>
      );
    };

export default Featurefive;
